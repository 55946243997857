import { Button, Divider, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';

import APIErorr from '../../../Components/APIErorr';


import 'react-image-crop/dist/ReactCrop.css'
import { useDispatch, useSelector } from 'react-redux';
import { addEditPartnerCardSettings, reset as restAddEditPartnerCardSettings } from '../../../../redux/slice/Settings/PartnerCard/addEditPartnerCardSettingSlices';
import Progress from '../../../Components/Progress';
import SuccessMsg from '../../../Components/SuccessMsg';

import { t } from 'i18next';
import CardErorr from '../../../Components/CardErorr';







function AddEditPartnerCard({PartnerCard, control , cardType}) {

    const [setSavePartnerCardFun, handleClose] = control || [null, null];
    const partnerCardData = PartnerCard
    const [searchParams] = useSearchParams();
    const [showsuccessMsg, setShowsuccessMsg] = useState(false);

    const shopId = searchParams.get("ShopId")
    const [showAlertMsg, setShowAlertMsg] = useState({

        textName: false
    })

    const navigate = useNavigate();

    const dispatch = useDispatch()
    const addEditPartnerCardSettingReduser = useSelector((state) => state.addEditPartnerCardSettingReduser);




    const [object, SetObject] = useState(partnerCardData === null ?

        {
            "id": 0,
            "name": "",
            "type": cardType  || searchParams.get(("Type")) || 1,
            "note": "",
        } : partnerCardData


    )






    const handelSavePartnerCard = () => async (dispatch) => {

        if (object.name === '') {
            setShowAlertMsg({ ...showAlertMsg, textName: true })


            return;
        }


        setShowsuccessMsg(false)
        await dispatch(addEditPartnerCardSettings({ shopId, object }))


    }








    useEffect(() => {



        if (addEditPartnerCardSettingReduser.loading === "succeeded") {
            if (object.id !== 0) {


                if (handleClose !== null) {
                    handleClose(true)

                } else {
                    //// for mobile window size save back to item page

                    handelCancelButton();


                }



            } else {




                setShowsuccessMsg(true)

                SetObject({

                    "id": 0,
                    "name": "",
                    "type": searchParams.get(("Type")) || 1,
                    "note": "",

                })







            }

            dispatch(restAddEditPartnerCardSettings())


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addEditPartnerCardSettingReduser])















    useEffect(() => {
        ///For desktop window size only

        if (setSavePartnerCardFun != null) {

            setSavePartnerCardFun(() => () => dispatch(handelSavePartnerCard()))

        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object, setSavePartnerCardFun])





    ///For Mobile window size only
    const handelSaveButton = () => {
        dispatch(handelSavePartnerCard());


    }


    ///For Mobile window size only

    const handelCancelButton = () => {
        navigate({

            pathname: "/Dashboard/Settings/PartnerCard",
            search: "?" + searchParams.toString()


        })

    }






    const handleChange = (event) => {

        let value = event.target.value;








        if (event.target.name === "name" && value !== '') {

            setShowAlertMsg({ ...showAlertMsg, textName: false })


        }




        SetObject({ ...object, [event.target.name]: value });


    };




    return (
        <Box component='form' sx={{ margin: setSavePartnerCardFun === null && 2 }}>


            {showsuccessMsg && <SuccessMsg msg={t("MSG3")} />}

            {addEditPartnerCardSettingReduser.loading === 'pending' && <Progress />}
            {(addEditPartnerCardSettingReduser.loading === 'failed' && addEditPartnerCardSettingReduser.httpCode !== 409) && <APIErorr reduser={addEditPartnerCardSettingReduser} />}
            {(addEditPartnerCardSettingReduser.loading === 'failed' && addEditPartnerCardSettingReduser.httpCode === 409) &&  <CardErorr Msg={t("MSG22")} /> }



            <TextField disabled={addEditPartnerCardSettingReduser.loading === 'pending'} error={showAlertMsg.textName} helperText={object.name === '' && t("MSG8")} onChange={handleChange} value={object.name} name="name" inputProps={{ size: 15, maxLength: "15", required: true }} id="name" sx={{ margin: 1 }} fullWidth label={t("Name")} variant="standard" type="text" />
            <Divider sx={{ margin: 2 }} />

            <TextField
                id="name"
                label={t("Note")}
                disabled={addEditPartnerCardSettingReduser.loading === 'pending'}
                error={showAlertMsg.note}
                onChange={handleChange}
                multiline
                fullWidth
                rows={4}
                defaultValue=""
                variant="standard"
                value={object.note} 
                name="note" 
                sx={{ margin: 1 }}
            />
            <Divider sx={{ margin: 2 }} />

            <Box sx={{ display: setSavePartnerCardFun !== null && 'none' }}>

                <Divider sx={{ margin: 2 }} />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: 1 }}>
                    <Button variant='text' onClick={handelSaveButton}>{t("Save")}</Button>
                    <Button variant='text' onClick={handelCancelButton}>{t("Cancel")}</Button>




                </Box>


            </Box>


        </Box>
    )
}

export default AddEditPartnerCard