import { Button, Box, MenuItem, Menu, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

function FilterSettingComp() {

  const [searchParams, setSearchParams] = useSearchParams();

  const Account = useSelector((state) => state.accountReduser);
  const [shopId, setShopId] = useState(searchParams.get(("ShopId")) || null)
  const [openMenuAnchor, setOpenMenuAnchor] = useState(null);

  const isOpenMenu = Boolean(openMenuAnchor);


  useEffect(() => {

    setShopId(searchParams.get('ShopId'))



  }, [searchParams])



  const shopName = Account.response.shopsEntities.map((data => {
    const id = data.id.toString();
    if (shopId.toString() === id) {
      return data.name;
    } else {
      return "";
    }
  }))


  const handleClickFiltersMenu = (event) => {

    setOpenMenuAnchor(event.currentTarget)
  };
  const handleCloseFilterMenu = () => {


    setOpenMenuAnchor(null)



  };


  const handelChangeFilterMenu = (e, index) => {


    if (e.target.id === "ShopName") {
      searchParams.set("ShopId", index)
      setSearchParams(searchParams);

    }
    handleCloseFilterMenu();

  }


  return (
    <Box>





        <Button onClick={(e) => handleClickFiltersMenu(e)} id="BTNShopMenu" variant='outlined' sx={{ paddingBottom: 1, margin: 1, width: { xs: "96%", sm: "auto" } }}><StorefrontIcon /> <Typography sx={{ marginLeft: 1 }} variant='subtitle1'>{shopName}</Typography>
        </Button>

        <Menu
          id="ShopMenuList"
          aria-labelledby="ShopMenuList"
          anchorEl={openMenuAnchor}
          open={isOpenMenu}
          onClose={handleCloseFilterMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',


          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box sx={{ minWidth: { xs: "330px", sm: "auto" } }}>

            {openMenuAnchor !== null ? openMenuAnchor.id === "BTNShopMenu" ?


              Account.response.shopsEntities.map((data, index) => (

                <MenuItem sx={{ textAlign: "center" }} id="ShopName" key={index} onClick={(e) => handelChangeFilterMenu(e, data.id)}>{data.name}</MenuItem>

              )) : ""
              : ""
            }


          </Box>

        </Menu>

    </Box>
  )
}

export default FilterSettingComp