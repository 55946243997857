import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDeviceId } from "../../../../Dashboard/hepler";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
  response: {},
  loading: 'idle',
  httpCode: 0,
  errorMsg: "",
  errorData: {},
};


export const deleteCategorySetting = createAsyncThunk(
  "deleteCategorySetting/deleteCategorySetting",

  async (dispatch, { rejectWithValue }) => {
    try {

      const shopId = dispatch.shopId
      const CategoryObject = dispatch.categoryObject






      const { data } = await axios.post(url + "/Apps/API/CMS/Settings/Category/delete?ShopId=" + shopId + "&DeviceId="+getDeviceId()+"&QRKey=0",
        {
          "id": CategoryObject.id,
          "name": CategoryObject.name,
          "tybe": CategoryObject.tybe,
        
        }
        ,
        {
          withCredentials: true
        })
      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);






export const DeleteCategorySettingSlices = createSlice({

  name: "DeleteCategorySetting",
  initialState,

  reducers: {
    reset: () => initialState,




  },



  extraReducers: {
    [deleteCategorySetting.pending]: (state) => {
      state.loading = 'pending';
    },
    [deleteCategorySetting.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
    },
    [deleteCategorySetting.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }
      } catch {

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    }








  }

})




export const { reset } = DeleteCategorySettingSlices.actions

export default DeleteCategorySettingSlices.reducer;