import { CacheProvider } from '@emotion/react'
import { StylesProvider } from '@mui/styles'
import React, { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles';
import createCache from "@emotion/cache";
import { useTranslation } from 'react-i18next';
import rtlPlugin from "stylis-plugin-rtl";
import {  useParams, useSearchParams } from 'react-router-dom';
import { create } from "jss";
import { jssPreset } from '@mui/styles';
import rtl from "jss-rtl";
import { useDispatch, useSelector } from 'react-redux';
import { DashboardTheme } from './Theme';
import { Box } from '@mui/system';
import { prefixer } from 'stylis';
import { LoginByTokenActions } from '../redux/slice/User/UserSlice';
import { useState } from 'react';
import APIErorr from './Components/APIErorr';
import CardErorr from './Components/CardErorr';
import { t } from 'i18next';
import { defultAppViewPageView, getFindIndexAppViewPageView } from './hepler';
import { Card } from '@mui/material';
import Movements from './Pages/Warehouse/Components/Movements';
import Progress from './Components/Progress';
import ItemsInventory from './Pages/Reports/Pages/ItemsInventory';
import NoteAndExtraInventory from './Pages/Reports/Pages/NoteAndExtraInventory';


export default function AppView() {

    const [searchParams] = useSearchParams();
    const [firstRun, setFirstRun] = useState(true);


    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
    const { i18n } = useTranslation();
    document.dir = i18n.dir();
    const theme = DashboardTheme(i18n.dir());

    const [token] = useState(searchParams.get(("Token")) || null)
    const [deviceId] = useState(searchParams.get(("DeviceId")) || null)


    let { viewPage } = useParams();


    const cacheRtl = createCache({
        key: theme.direction === "rtl" ? "muirtl" : "muiltrz",
        prepend: true,
        stylisPlugins: theme.direction === "rtl" ? [prefixer, rtlPlugin] : [prefixer],

    });

    const styleMainSection = {

        backgroundColor: "#ffffff", backgroundImage: "linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);"
        ,
        minHeight: "100vh",


    }

    useEffect(() => {

        if (firstRun) {

            i18n.changeLanguage("en")
            setFirstRun(false)

        }



    },[firstRun,i18n])


    return (


        <CacheProvider value={cacheRtl}>

            <StylesProvider jss={jss}>
                <Box component="section" sx={styleMainSection}>

                    <ThemeProvider theme={theme}>

                        <Box sx={{ pt: 1 }}>


                            {(token === null || deviceId === null) ? <CardErorr Msg={t("UnauthorizedAccess")}></CardErorr>
                                : <ViewPage viewPage={viewPage} />
                            }



                        </Box>


                    </ThemeProvider>
                </Box>

            </StylesProvider>

        </CacheProvider>



    )
}


const ViewPage = ({ viewPage }) => {

    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    const [token] = useState(searchParams.get(("Token")) || null)
    const [deviceId] = useState(searchParams.get(("DeviceId")) || null)

    const loginReuder = useSelector((state) => state.userReduser);


    useEffect(() => {


        if (token != null) {

            dispatch(LoginByTokenActions({ "token": token }))
        }

    }, [token, dispatch])




    if (loginReuder.loading === "pending" || loginReuder.loading === 'idle') {

        return (<Progress />)
    }
    if (loginReuder.loading === "failed") {
        return (<APIErorr reduser={loginReuder} />)
    }

    localStorage.setItem('deviceId', deviceId);



    return (


        <Card>

            {viewPage === defultAppViewPageView[0] && <Movements />}
            {viewPage === defultAppViewPageView[1] && <ItemsInventory/>}
            {viewPage === defultAppViewPageView[2] && <NoteAndExtraInventory/>}


            {getFindIndexAppViewPageView(viewPage) === -1 && <CardErorr Msg={t("PageNotfound")}></CardErorr>}




        </Card>





    )










}
