import { Avatar, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, useTheme } from '@mui/material'
import React from 'react'
import { t } from 'i18next';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { visuallyHidden } from '@mui/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import PropTypes from 'prop-types';
import SquareIcon from '@mui/icons-material/Square';
import { getHexColor } from '../../../hepler';
import { getNoteAndExtraSetting } from '../../../../redux/slice/Settings/NoteAndExtra/getAllNoteAndExtraSettingSlices';


function TablePaginationActions(props) {


  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t("FirstPage")}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t("PreviousPage")}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t("NextPage")}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t("LastPage")}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {


    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant='head'
            sx={{ textAlign: headCell.textAlignEnd && 'End' }}
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label).toUpperCase()}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


function createData(id, image, name, color , qty) {
  return { id, image, name, color ,qty }

}

const headCells = [


  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    textAlignEnd: false,
    label: 'Id',
  },
  {
    id: 'image',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'Image',
  },


  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'ItemName',
  },



  {
    id: 'qty',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'QuantityAvailable',
  },
];






const getAvatar = (color, name, image, isCategory) => {



  if (color === 0) {
    return <Avatar alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

  }


  if (color !== null) {



    return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(color), marginLeft: 1, marginRight: 1 }} > </Avatar>


  }

  return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}







function NoteAndExtraInventory() {


  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const itemsList = []


  const pageNumber = Number(searchParams.get(("PageNumber")) || 0)
  const pageSize = Number(searchParams.get(("PageSize")) || 5)
  const sortOrder = searchParams.get(("SortOrder")) || "desc"
  const sortBy = searchParams.get(("SortBy")) || "id"
  const [dense, setDense] = useState(true);
  const shopId = searchParams.get("ShopId")
  const mainReduser = useSelector((state) => state.noteAndExtraSettingReduser);

 

  const [isFisrtLunch, setFisrtLunch] = useState(true)


  useEffect(() => {
    dispatch(getNoteAndExtraSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  




  useEffect(() => {
    if (!isFisrtLunch) {
      searchParams.delete("CategoryId")
      setSearchParams(searchParams)
      const pageNumber = 0;
      const pageSize = 5;
      const sortBy = 'id';
      const sortOrder = 'desc';
      dispatch(getNoteAndExtraSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
    }
    setFisrtLunch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId])



  
  useEffect(() => {


    if (mainReduser.loading === 'succeeded') {

      dispatch(getNoteAndExtraSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ pageNumber, pageSize, sortBy, sortOrder])

 





  if (mainReduser.loading === "failed") {
    return (<APIErorr reduser={mainReduser} />)
  }

  if (mainReduser.loading === "pending" || mainReduser.loading === "idle"  || shopId === null) {


    return (<Progress />)
  }



  const totalElements = mainReduser.response.totalElements


  const setPageNumber = (value) => {
    searchParams.set("PageNumber", value)
    setSearchParams(searchParams);
  }
  const setPageSize = (value) => {
    searchParams.set("PageSize", value)
    setSearchParams(searchParams);
  }

  const setSortOrder = (value) => {
    searchParams.set("SortOrder", value)
    setSearchParams(searchParams);
  }
  const setSortBy = (value) => {
    searchParams.set("SortBy", value)
    setSearchParams(searchParams);
  }





  mainReduser.response.content.forEach((data) => {
    itemsList.push(createData(data.id, data.image, data.name, data.color ,data.qty))
  })






  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = itemsList.map((n) => n.name);


      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);

  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(0);

  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const emptyRows = pageNumber > 0 ? Math.max(0, (1 + pageNumber) * pageSize - totalElements) : 0;

  const handleRequestSort = (event, property) => {

    if (property !== "actions") {
      const isAsc = sortBy === property && sortOrder === 'asc';

      setSortOrder(isAsc ? 'desc' : 'asc');
      setSortBy(property);
    }
  };


  













  return (

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 550 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={sortOrder}
              orderBy={sortBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={itemsList.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {itemsList
                .map((row, index) => {

                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell >{row.id}</TableCell>
                        <TableCell >{getAvatar(row.color, row.name, row.image, false)}</TableCell>
                        <TableCell >{row.name}</TableCell>
                        <TableCell  >{row.qty}</TableCell>

                      </TableRow>

                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50 ,100]}
          component="div"
          count={totalElements}
          rowsPerPage={pageSize}

          page={pageNumber}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}

          SelectProps={{
            inputProps: {
              'aria-label': t('RowsPerPage'),
            },
            native: true,
          }}

          ActionsComponent={TablePaginationActions}


        />
      </Paper>
      <Box sx={{ display: "flex", flexFlow: "row", justifyContent: "space-between", alignContent: "end" }}>



        <Box sx={{ display: "flex" }}>



          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label={t("Densepadding")}
          />

        </Box>

      </Box>

    </Box>

  )
}

export default NoteAndExtraInventory