import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDeviceId } from "../../../../Dashboard/hepler";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const deleteItemSetting = createAsyncThunk(
    "deleteItemSetting/deleteItemSetting",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
        const itemObject = dispatch.itemObject

        



        const { data } = await axios.post(url + "/Apps/API/CMS/Settings/Items/delete?ShopId="+shopId+"&DeviceId="+getDeviceId()+"&QRKey=0", 
        {
            "id": itemObject.id,
            "name": itemObject.name,
            "tybe": itemObject.tybe,
            "priceTakeAway": itemObject.priceTakeAway,
            "priceTable": itemObject.priceTable,
            "priceDelivery": itemObject.priceDelivery,
            "priceOnDial": itemObject.priceOnDial,
            "priceOnline": itemObject.priceOnline,
            "priceDriveThru": itemObject.priceDriveThru,
            "positions": itemObject.positions,
            "parcode": itemObject.parcode,
            "categoryId":  itemObject.categoryId,
            "image": itemObject.image,
            "color": itemObject.color,
            "printers": itemObject.printers
        } 
         ,
          {
            withCredentials: true
          })
        return data;
      } catch (err) {
  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );




  

  export const DeleteItemSettingSlices = createSlice({

    name: "DeleteItemSetting",
    initialState,
  
    reducers: {
      reset: () => initialState,  

  
  
  
    },
  
  
  
    extraReducers: {
      [deleteItemSetting.pending]: (state) => {
        state.loading = 'pending';
      },
      [deleteItemSetting.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [deleteItemSetting.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })



  
  export const { reset } = DeleteItemSettingSlices.actions
  
  export default DeleteItemSettingSlices.reducer;