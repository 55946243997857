
import { Alert, Avatar, Button,  CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, IconButton, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { visuallyHidden } from '@mui/utils';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import ItemsListWithCheckComp from '../../Settings/Components/ItemsListWithCheckComp';
import { getHexColor } from '../../../hepler';
import SquareIcon from '@mui/icons-material/Square';
import DeleteIcon from '@mui/icons-material/Delete';
import AddEditPartnerCard from '../../Settings/Pages/AddEditPartnerCard';
import { getAllPartnerCardSetting } from '../../../../redux/slice/Settings/PartnerCard/getAllPartnerCardPagesSettingSlices';
import { useDispatch, useSelector } from 'react-redux';
import APIErorr from '../../../Components/APIErorr';
import Progress from '../../../Components/Progress';
import { saveMovementsSetting } from '../../../../redux/slice/Warehouse/saveMovementSlices';
import CardErorr from '../../../Components/CardErorr';
import NoteAndExtraListWithCheckComp from '../../Settings/Components/NoteAndExtraListWithCheckComp';

const getAvatar = (color, name, image, isCategory) => {



  if (color === 0) {
    return <Avatar alt={name} src={`data:image/jpeg;base64,${image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

  }


  if (color !== null) {



    return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(color), marginLeft: 1, marginRight: 1 }} > </Avatar>


  }

  return <Avatar alt={name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}




function AddEditDialog({ control, data, searchParamsData }) {



  const [open, setOpen] = control
  const partnerCardData = data
  const [shopId, cardType] = searchParamsData

  const dispatch = useDispatch();


  const addEditPartnerCardSettingReduser = useSelector((state) => state.addEditPartnerCardSettingReduser);

  const [savePartnerCardFun, setSavePartnerCardFun] = useState(() => { });

  const handleSave = () => {

    savePartnerCardFun();

  }



  const handleClose = (isEdit) => {

    if (isEdit) {


      if (addEditPartnerCardSettingReduser.loading === 'succeeded') {
        setOpen(false);
        dispatch(getAllPartnerCardSetting({ shopId, cardType }))


      }



    } else {

      setOpen(false);
      dispatch(getAllPartnerCardSetting({ shopId, cardType }))



    }



  };

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => handleClose(false)}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{partnerCardData === null ? t("AddNew") : partnerCardData.name}</DialogTitle>
        <DialogContent dividers={true}>

          <AddEditPartnerCard control={[setSavePartnerCardFun, handleClose]} PartnerCard={partnerCardData} cardType={cardType} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>{t("Save")}</Button>
          <Button onClick={() => handleClose(false)}>{t("Cancel")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}






const SelectMenu = ({ data, cardType }) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [openDialog, setOpenDialog] = useState(false);

  const shopId = searchParams.get("ShopId")

  const [id, setId] = useState('');

  const dataList = data.filter((obj) => (obj.type === cardType))

  const [dataObjects] = useState(null)

  useEffect(() => {
    const id = searchParams.get("SelectMenuId") || '';


    if (id === '') {


      if (dataList !== null && dataList !== undefined) {

        if (dataList.length > 0) {

          searchParams.set("SelectMenuId", dataList[0].id)
          setSearchParams(searchParams)

        }


      }

    }


    setId(id)


  }, [dataList, searchParams, setSearchParams])





  const handleChange = (event) => {
    const value = event.target.value;
    if (value === '') {

    } else {

      searchParams.set("SelectMenuId", value)
      setSearchParams(searchParams)


    }
  };



  return (
    <>
      <FormControl sx={{ margin: 1, minWidth: 250 }}>
        <FormHelperText>{(cardType === 1 || cardType === 3) ? t("Supplier") : t("Reason")}</FormHelperText>

        <Select
          value={id}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >


          {dataList.map((data, index) => (

            <MenuItem key={index} value={data.id}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginLeft: 2 }}>{data.name}</Typography>

              </Box>
            </MenuItem>



          ))}

          <MenuItem onClick={() => { setOpenDialog(true) }} value={''}>
            <em>{t("AddNew")}</em>
          </MenuItem>

        </Select>

      </FormControl>
      <AddEditDialog control={[openDialog, setOpenDialog]} searchParamsData={[shopId, cardType]} data={dataObjects} />

    </>
  )




}


const headCells = [


  {
    id: 'itemId',
    numeric: false,
    disablePadding: true,
    textAlignEnd: false,
    label: 'itemId',
  },
  {
    id: 'image',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'Image',
  },


  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'ItemName',
  },



  {
    id: 'Quantity',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'Quantity',
  },


  {
    id: 'Unit',
    numeric: true,
    disablePadding: false,
    textAlignEnd: false,
    label: 'Unit',
  },



  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    textAlignEnd: true,
    label: 'Actions',
  },
];



EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {


    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            variant='head'
            sx={{ textAlign: headCell.textAlignEnd && 'End' }}
          >

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.label).toUpperCase()}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


function SelectIteemsDialog(props) {
  const [open, setOpen] = props.control
  const [curenetServiceUse] = useState(0);


  const [dataObject, setDataObject, movementsType] = props.dataObject

  const descriptionElementRef = React.useRef(null);

  const handleClose = () => {

    setOpen(false);

  };



  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (




    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        onClose={() => handleClose(false)}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{t("Select Items")}</DialogTitle>
        <DialogContent dividers={true}>


          {movementsType <= 2 ?

            <ItemsListWithCheckComp listType="2" control={[dataObject, setDataObject]} adapterTybe={curenetServiceUse} disabled={false} />


            :

            <NoteAndExtraListWithCheckComp listType="2" control={[dataObject, setDataObject]} adapterTybe={curenetServiceUse} disabled={false} />


          }










        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{t("Done")}</Button>
        </DialogActions>
      </Dialog>
    </div>









  )



}



const ItemList = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortOrder = searchParams.get(("SortOrder")) || "desc"
  const sortBy = searchParams.get(("SortBy")) || "id"
  const [openDialog, setOpenDialog] = useState(false);

  const [dataObject, setDataObject] = props.dataObject

  const movementsType = props.movementsType;



  const setSortOrder = (value) => {
    searchParams.set("SortOrder", value)
    setSearchParams(searchParams);
  }
  const setSortBy = (value) => {
    searchParams.set("SortBy", value)
    setSearchParams(searchParams);
  }

  const handelOpenDialog = () => {
    setOpenDialog(true)
  }



  const handleRequestSort = (event, property) => {

    if (property !== "actions") {
      const isAsc = sortBy === property && sortOrder === 'asc';

      setSortOrder(isAsc ? 'desc' : 'asc');
      setSortBy(property);
    }
  };



  const handelDelete = (id) => {

    const tempList = dataObject.itemList.filter(function (obj) {
      return obj.itemId !== id;
    });


    setDataObject({ ...dataObject, itemList: tempList })


  }


  const handelChangeQTY = (e, id) => {

    const value = e.target.value;

    if (movementsType === 2) {

      const ob = dataObject.itemList.find(ob => ob.itemId === id)



      if (value !== null || value !== '') {
        if (ob.tempQty < value) {
          setDataObject({
            itemList: dataObject.itemList.map(ob => (ob.itemId === id ? { ...ob, "qtyNew": value, "showErrQty": true } : ob))
          })


          return;


        }

      }



    }


    setDataObject({
      itemList: dataObject.itemList.map(ob => (ob.itemId === id ? { ...ob, "qtyNew": value, "showErrQty": false } : ob))



    })


  }

  return (


    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>

          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={0}
              order={sortOrder}
              orderBy={sortBy}
              onSelectAllClick={() => { }}
              onRequestSort={handleRequestSort}
              rowCount={0}
            />
            <TableBody>

              {dataObject.itemList
                .map((row, index) => {
                  return (

                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >



                        <TableCell >{row.itemId}</TableCell>
                        <TableCell >{getAvatar(row.color, row.name, row.image, false)}</TableCell>
                        <TableCell >{row.name}</TableCell>
                        <TableCell ><TextField  type="number" inputProps={{ type: "number", step: "0.01" }} error={row.qtyNew <= 0 || row.qtyNew === null || row.showErrQty} helperText={row.qtyNew <= 0 || row.qtyNew === null ? t("MSG25") : row.showErrQty ? t("MSG27") + " " + row.qty : ""} value={row.qtyNew} onChange={(e) => handelChangeQTY(e, row.itemId)} ></TextField></TableCell>
                        <TableCell >{row.unit === null ? t("piece") : t(row.unit)}</TableCell>
                        <TableCell sx={{ textAlign: "start" }}>
                          <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "end", alignContent: "start", flexFlow: "wrap" }}>

                            <IconButton edge="start" aria-label="delete" onClick={() => handelDelete(row.itemId)} sx={{ display: 'flex', flexDirection: "column" }}>
                              <Avatar sx={{ bgcolor: "error.dark" }} variant="square" >
                                <DeleteIcon />
                              </Avatar>
                              <Typography variant='body2'>{t("Delete")}</Typography>
                            </IconButton>
                          </Box>
                        </TableCell>



                      </TableRow>


                    </React.Fragment>






                  );
                })}

            </TableBody>


          </Table>

        </TableContainer>
      </Paper>

      <Button onClick={handelOpenDialog} variant='contained' sx={{ width: 100, height: 40, marginTop: 2 }}>{t("AddNew")}</Button>
      <SelectIteemsDialog control={[openDialog, setOpenDialog]} dataObject={[dataObject, setDataObject, movementsType]} />

    </Box>




  )


}





function Movements() {
  const [searchParams] = useSearchParams() || null;
  const dispatch = useDispatch()
  const cardReduser = useSelector((state) => state.partnerCardPagesSettingReduser);

  const saveMovementReduser = useSelector((state) => state.saveMovementReduser);


  console.log("saveMovementReduser", saveMovementReduser)

  const [dataObject, setDataObject] = useState({
    itemList: [],
  })


  const [shopId, setShopId] = useState(searchParams.get("ShopId") || null)
  const [userName, setUserName] = useState(searchParams.get("UserName") || null)
  const [userId, setUserId] = useState(searchParams.get("UserId") || null)
  const [selectMenuId, setSelectMenuId] = useState(searchParams.get("SelectMenuId") || '');

  const [movementsType, setMovementsType] = useState(searchParams.get("movementsType") || null);
  const [cardType, setCardType] = useState(0);


  useEffect(() => {
    setShopId(searchParams.get("ShopId") || null)
    setUserName(searchParams.get("UserName") || null)
    setUserId(searchParams.get("UserId") || null)
    setSelectMenuId(searchParams.get("SelectMenuId") || '')
    setMovementsType(Number(searchParams.get("movementsType")) || null)
  }, [searchParams])


  useEffect(() => {


    if (movementsType === 1 || movementsType === 3) {
      setCardType(1)


    }

    if (movementsType === 2 || movementsType === 4) {
      setCardType(2)
    }


  }, [movementsType, setCardType])





  useEffect(() => {

    if (shopId) {

      dispatch(getAllPartnerCardSetting({ shopId, cardType }))

    }

  }, [shopId, cardType, dispatch])



  const handelChange = (event) => {


    setDataObject({ ...dataObject, [event.target.name]: event.target.value });



  }

  const CreatSaveList = (id, itemId, itemName, qty) => {
    return { id, itemId, itemName, qty }
  }

  const handelSave = () => {

    const movementsItemsEntities = [];
    dataObject.itemList.forEach(e => {
      movementsItemsEntities.push(CreatSaveList(0, e.itemId, e.name, Number(e.qtyNew)))

    });


    const dataSaved = { "id": 0, "note": dataObject.note, "type": movementsType, "userId": userId, "userName": userName, "dateTime": null, movementsItemsEntities, partnerCardEntity: { "id": Number(selectMenuId) } }

    dispatch(saveMovementsSetting({ shopId, dataSaved }))

  }






  if (shopId === null || userId === null || userName === null || movementsType === null) {



    return <CardErorr Msg={t("UnauthorizedAccess")}></CardErorr>
  }


  if (cardReduser.loading === "failed") {
    return (<APIErorr reduser={cardReduser} />)

  }



  if (cardReduser.loading === "pending" || cardReduser.loading === "idle" || cardType === 0) {


    return (<Progress />)
  }




  const checkSaveButton = () => {




    if (dataObject.itemList.length < 1) {




      return true;

    }


    if (selectMenuId === '') {


      return true;
    }


    let result = false;


    if (movementsType === 2) {

      result = dataObject.itemList.some(el => el.tempQty < el.qtyNew);


      return result
    }





    result = dataObject.itemList.some(el => el.qtyNew <= 0 || el.qtyNew === null);


    return result;

  }


  return (
    <Box sx={{ padding: 2 }}>




      <h1>Type={movementsType}</h1>


      <CardContent>

        {saveMovementReduser.loading === "pending" || saveMovementReduser.loading === "succeeded" ? <ViewSuccsesOrloading isSucsses={saveMovementReduser.loading} />


          :


          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", paddingBottom: 4 }}>

              <SelectMenu data={cardReduser.response} cardType={cardType} />

              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", justifyItems: "star" }}>
                <Typography variant='body1'>UserId : {userId} </Typography>
                <Typography variant='body1'>UserName : {userName} </Typography>

              </Box>

              <TextField
                id="note"
                label={t("Note")}
                multiline
                fullWidth
                rows={4}
                name="note"
                defaultValue=""
                variant="outlined"
                value={dataObject.note}
                onChange={handelChange}
                sx={{ margin: 1 }}
              />




            </Box>
            <Divider sx={{ margin: 2 }} />
            <ItemList dataObject={[dataObject, setDataObject]} movementsType={movementsType} />
            <Box >

              <Divider sx={{ margin: 2 }} />
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", gap: 1 }}>
                <Alert sx={{ display: dataObject.itemList.length > 1 && 'none' }} severity="error">{t("MSG24")}</Alert>

                <Alert sx={{ display: (selectMenuId !== '' && selectMenuId !== null) && 'none' }} severity="error">{(movementsType === 1 || movementsType === 3) ? t("MSG23") : ("MSG26")}</Alert>

                <Button disabled={checkSaveButton()} variant='text' onClick={handelSave}>{t("Save")}</Button>




              </Box>


            </Box>
          </Box>}






      </CardContent>



    </Box>
  )
}




const ViewSuccsesOrloading = ({ isSucsses }) => {


  const CreateNewTransaction = () => {


    window.location.reload(false);


  }

  return (


    <Box >

      {isSucsses !== 'succeeded' ?
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2, height: "100vh" }}>


          <Progress />

        </Box>
        :
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 2, height: "100vh" }}>

          <Alert severity="success">{t("MSG3")}</Alert>

          <Button onClick={CreateNewTransaction} variant='text'>{t("CreateNewTransaction")}</Button>
        </Box>
      }



    </Box>



  )





}



export default Movements