import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDeviceId } from "../../../../Dashboard/hepler";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
  response: {},
  loading: 'idle',
  httpCode: 0,
  errorMsg: "",
  errorData: {},
};


export const deletePartnerCardSetting = createAsyncThunk(
  "deletePartnerCardSetting/deletePartnerCardSetting",

  async (dispatch, { rejectWithValue }) => {
    try {

      const shopId = dispatch.shopId
      const PartnerCardObject = dispatch.PartnerCardObject






      const { data } = await axios.post(url + "/Apps/API/CMS/Settings/PartnerCard/delete?ShopId=" + shopId + "&DeviceId="+getDeviceId()+"&QRKey=0",
        {
          ...PartnerCardObject
        
        }
        ,
        {
          withCredentials: true
        })
      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);






export const DeletePartnerCardSettingSlices = createSlice({

  name: "DeletePartnerCardSetting",
  initialState,

  reducers: {
    reset: () => initialState,




  },



  extraReducers: {
    [deletePartnerCardSetting.pending]: (state) => {
      state.loading = 'pending';
    },
    [deletePartnerCardSetting.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
    },
    [deletePartnerCardSetting.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }
      } catch {

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    }








  }

})




export const { reset } = DeletePartnerCardSettingSlices.actions

export default DeletePartnerCardSettingSlices.reducer;