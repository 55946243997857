/* eslint-disable array-callback-return */
import {
    Avatar,
    Button, CircularProgress, Divider, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarDialog from './CalendarDialog';

import {
    defultReprtsPageView, formatDate, getPaymentMethodName, getServiceName, getShiftName, getVoidServiceName
} from '../../../hepler';
import CachedIcon from '@mui/icons-material/Cached';
import GroupIcon from '@mui/icons-material/Group';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentsIcon from '@mui/icons-material/Payments';
import { useTranslation } from 'react-i18next';
import { getAllOnlineSetting } from '../../../../redux/slice/Settings/online/getAllIOnlineSettingSlices';
import Progress from '../../../Components/Progress';
import DevicesIcon from '@mui/icons-material/Devices';
function FilterReportsComp() {
    let { viewPage } = useParams();
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const { i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [openMenuAnchor, setOpenMenuAnchor] = useState(null);
    const [openDialogStartCalDialog, setOpenStartCalDialog] = useState(false);
    const [openDialogEndCalDialog, setOpenEndCalDialog] = useState(false);
    const [shopId, setShopId] = useState(searchParams.get("ShopId") || null);
    const [startDateValue, setStartDateValue] = useState(searchParams.get("From") || null);
    const [endDateValue, setEndDateValue] = useState(searchParams.get("To") || null);
    const [shiftName, setShiftName] = useState(searchParams.get("ShiftName") || null);
    const [priceType, setPriceType] = useState(searchParams.get("PriceType") || null);
    const [voidService, setVoidService] = useState(searchParams.get("VoidService") || null);
    const [paymentMethod, setPaymentMethod] = useState(searchParams.get("PaymentMethod") || null);
    const [onlineServices, setOnlineServices] = useState(searchParams.get("onlineServices") || null);
    const [onlineObject, setOnlineObject] = useState(null);



    const onlineSettingsReduser = useSelector((state) => state.getAllOnlineSettingsReduser);

    const refToggleButtonGroup = useRef(null);
    const ListShftNumberAndPriceType = [0, 1, 2, 3, 4, 5, 6];
    const isOpenMenu = Boolean(openMenuAnchor);
    const Account = useSelector((state) => state.accountReduser);

    useEffect(() => {
        setShopId(searchParams.get('ShopId'));
        setStartDateValue(searchParams.get('From'));
        setEndDateValue(searchParams.get('To'));
        setPriceType(searchParams.get('PriceType'));
        setVoidService(searchParams.get('VoidService'));
        setShiftName(searchParams.get('ShiftName'));
        setPaymentMethod(searchParams.get('PaymentMethod'));
        setOnlineServices(searchParams.get('onlineServices'));
    }, [searchParams]);




    useEffect(() => {

        if (Number(priceType) === 6) {

            dispatch(getAllOnlineSetting({ shopId }));

        }else{
            setOnlineServices(null);

        }


    }, [priceType, dispatch, shopId]);



    useEffect(() => {


        if (onlineSettingsReduser.loading === "succeeded") {

            setOnlineObject(onlineSettingsReduser?.response?.find(online => Number(online.id) === Number(onlineServices)))

        }
    }, [onlineServices, onlineSettingsReduser]);









  

    const handleClickFiltersMenu = (event) => {
        setOpenMenuAnchor(event.currentTarget);
    };

    const handleClickOpenCalDialog = (isStartDate) => {
        if (isStartDate) {
            setOpenStartCalDialog(true);
        } else {
            setOpenEndCalDialog(true);
        }
    };

    const handleCloseCalDialog = (isStartDate) => {
        if (isStartDate) {
            setOpenStartCalDialog(false);
            setOpenEndCalDialog(true);
        } else {
            setOpenEndCalDialog(false);
        }
    };

    const handleChangeDate = (date, isStartDate) => {
        date = formatDate(date);
        if (isStartDate) {
            setStartDateValue(date);
        } else {
            setEndDateValue(date);
        }
        handleCloseCalDialog(isStartDate);
    };

    const handleCloseFilterMenu = () => {
        setOpenMenuAnchor(null);
    };

    const handleChangeFilterMenu = (e, index) => {
        const id = e.target.id;

        



        if (id === "ShopName") {
            setShopId(index);
        } else if (id === "PriceType") {

           
            setPriceType(index === -1 ? null : index);
        } else if (id === "ShiftName") {
            setShiftName(index === -1 ? null : index);
        } else if (id === "VoidServices") {
            setVoidService(index === -1 ? null : index);
        } else if (id === "PaymentMethod") {
            setPaymentMethod(index === -1 ? null : index);
        } else if (id === "OnlineServices") {



            setOnlineServices(index === -1 ? null : index);
        }
        handleCloseFilterMenu();
    };

    const updateSearchParams = () => {
        searchParams.set("ShopId", shopId);
        setSearchParams(searchParams);
        searchParams.set("From", startDateValue);
        setSearchParams(searchParams);
        searchParams.set("To", endDateValue);
        setSearchParams(searchParams);
        priceType !== null ? searchParams.set("PriceType", priceType) : searchParams.delete("PriceType");
        setSearchParams(searchParams);
        shiftName !== null ? searchParams.set("ShiftName", shiftName) : searchParams.delete("ShiftName");
        setSearchParams(searchParams);
        voidService !== null ? searchParams.set("VoidService", voidService) : searchParams.delete("VoidService");
        setSearchParams(searchParams);
        paymentMethod !== null ? searchParams.set("PaymentMethod", paymentMethod) : searchParams.delete("PaymentMethod");
        setSearchParams(searchParams);
        onlineServices !== null ? searchParams.set("onlineServices", onlineServices) : searchParams.delete("onlineServices");
        setSearchParams(searchParams);

        if (viewPage === defultReprtsPageView[3] || viewPage === defultReprtsPageView[4]) {
            resetSearchParams(3);
        }
    };

    const resetSearchParams = (type, SortBy) => {
        if (type === 1) {
            searchParams.delete("VoidService");
            setSearchParams(searchParams);
            searchParams.delete("PageNumber");
            setSearchParams(searchParams);
            searchParams.delete("PageSize");
            setSearchParams(searchParams);
            searchParams.delete("SortOrder");
            setSearchParams(searchParams);
            searchParams.delete("SortBy");
            setSearchParams(searchParams);
        } else if (type === 2) {
            searchParams.set("PageNumber", 0);
            setSearchParams(searchParams);
            searchParams.set("PageSize", 5);
            setSearchParams(searchParams);
            searchParams.set("SortOrder", "desc");
            setSearchParams(searchParams);
            searchParams.set("SortBy", SortBy);
            setSearchParams(searchParams);
        } else if (type === 3) {
            searchParams.set("PageNumber", 0);
            setSearchParams(searchParams);
            searchParams.set("PageSize", 5);
            setSearchParams(searchParams);
        }
    };

    const handleChangeButtonGroup = (event, newAlignment) => {
        const maxScrollLeft = refToggleButtonGroup.current.scrollWidth - refToggleButtonGroup.current.clientWidth;
        const value = maxScrollLeft / 5;

        switch (newAlignment) {
            case defultReprtsPageView[0]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? 0 : 0;
                resetSearchParams(1);
                break;
            case defultReprtsPageView[1]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? Number("-" + value) : value;
                resetSearchParams(1);
                break;
            case defultReprtsPageView[2]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? Number("-" + value * 2) : value * 2;
                resetSearchParams(1);
                break;
            case defultReprtsPageView[3]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? Number("-" + value * 4) : value * 4;
                resetSearchParams(2, "invoiceNumber");
                break;
            case defultReprtsPageView[4]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? Number("-" + value * 5) : value * 5;
                resetSearchParams(2, "businessDayName");
                break;
            case defultReprtsPageView[5]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? Number("-" + value * 6) : value * 6;
                resetSearchParams(2, "id");
                break;
            case defultReprtsPageView[6]:
                refToggleButtonGroup.current.scrollLeft = i18n.dir() === "rtl" ? Number("-" + maxScrollLeft) : maxScrollLeft;
                resetSearchParams(2, "id");
                break;
            default:
                break;
        }

        if (newAlignment != null) {
            navigate({
                pathname: "/Dashboard/Reports/" + newAlignment,
                search: "?" + searchParams.toString()
            });
        }
    };

    const getNameReport = () => {
        switch (viewPage) {
            case defultReprtsPageView[0]:
                return t("CashReports");
            case defultReprtsPageView[1]:
                return t("VoidedTransctionsReports");
            case defultReprtsPageView[2]:
                return t("ItemsSoldReports");
            case defultReprtsPageView[3]:
                return t("ViewInvoices");
            case defultReprtsPageView[4]:
                return t("StoreLogsReports");
            case defultReprtsPageView[5]:
                return t("ItemsInventory");
            case defultReprtsPageView[6]:
                return t("NoteAndExtraInventory");
            default:
                return t("PageNotfound");
        }
    };

    const handleChangeFilterMenuOnline = (e, index) => {

        setOnlineServices(index === -1 ? null : index);
        handleCloseFilterMenu();

    };






    const shopName = Account.response.shopsEntities.map((data) => {
        const id = data.id.toString();
        return shopId.toString() === id ? data.name : "";
    });

    const checkFilterButton = (buttonIdName) => {
        if (viewPage === defultReprtsPageView[4]) {
            return buttonIdName === "CalendarButton";
        }


        if (buttonIdName === "BTNOnlineServices" && Number(priceType) !== 6) {

            return false;

        }

        if ([defultReprtsPageView[5], defultReprtsPageView[6]].includes(viewPage)) {
            return false;
        }
        if (viewPage === defultReprtsPageView[3]) {
            return true;
        }
        return buttonIdName !== "BTNVoidServices";
    };

    return (
        <Box>
            <Typography
                variant='h4'
                color="info.main"
                sx={{ textAlign: "center", marginBottom: "10px" }}
            >
                {t("Filters").toUpperCase()}
            </Typography>

            <Box
                sx={{
                    display: 'flexWrap', flexFlow: "row", justifyContent: { xs: "start", sm: "center" },
                    alignItems: "center", marginTop: "5px", marginBottom: "1px", marginRight: "10px", marginLeft: "-5px"
                }}
            >







                <Button
                    onClick={handleClickFiltersMenu}
                    id="BTNShopMenu"
                    variant='outlined'
                    sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                >
                    <StorefrontIcon />
                    <Divider sx={{ margin: 1 }} />
                    {shopName}
                </Button>

                {checkFilterButton("CalendarButton") && (
                    <>
                        <Button
                            onClick={() => handleClickOpenCalDialog(true)}
                            variant='outlined'
                            sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                        >
                            <CalendarMonthIcon />
                            <Divider sx={{ margin: 1 }} />
                            {startDateValue}/{endDateValue}
                        </Button>
                        <CalendarDialog
                            open={openDialogStartCalDialog}
                            isStartDate={true}
                            onChangeDateFun={handleChangeDate}
                            value={startDateValue}
                            closeFun={handleCloseCalDialog}
                            title={t("FromDate")}
                        />
                        <CalendarDialog
                            open={openDialogEndCalDialog}
                            isStartDate={false}
                            minDate={startDateValue}
                            onChangeDateFun={handleChangeDate}
                            value={endDateValue}
                            closeFun={handleCloseCalDialog}
                            title={t("ToDate")}
                        />
                    </>
                )}

                {checkFilterButton("BTNShiftName") && (
                    <Button
                        onClick={handleClickFiltersMenu}
                        id="BTNShiftName"
                        variant='outlined'
                        sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                    >
                        <GroupIcon />
                        <Divider sx={{ margin: 1 }} />
                        {shiftName === null ? t('AllWorkShifts') : t("Shift") + " " + getShiftName(shiftName)}
                    </Button>
                )}

                {checkFilterButton("BTNPriceType") && (
                    <Button
                        onClick={handleClickFiltersMenu}
                        id="BTNPriceType"
                        variant='outlined'
                        sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                    >
                        <RoomServiceIcon />
                        <Divider sx={{ margin: 1 }} />
                        {priceType === null ? t('AllServices') : getServiceName(priceType)}
                    </Button>
                )}

                {checkFilterButton("BTNVoidServices") && (
                    <Button
                        onClick={handleClickFiltersMenu}
                        id="BTNVoidServices"
                        variant='outlined'
                        sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                    >
                        <CancelIcon />
                        <Divider sx={{ margin: 1 }} />
                        {voidService === null ? "None" : getVoidServiceName(voidService)}
                    </Button>
                )}


                {checkFilterButton("BTNOnlineServices") && (
                    onlineSettingsReduser.loading === "pending" || onlineSettingsReduser.loading === "idle" ?
                        <Box sx={{ display: "inline-flex", justifyContent: "center", alignItems: "center", padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }} >

                            <Progress />
                        </Box>
                        :

                        <Button
                            onClick={handleClickFiltersMenu}
                            id="BTNOnlineServices"
                            variant='outlined'
                            sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                        >


                            <DevicesIcon />
                            <Divider sx={{ margin: 1 }} />

                            {onlineObject?.image ? (
                                <Avatar
                                    variant='square'
                                    alt={onlineObject.name}
                                    src={`data:image/jpeg;base64,${onlineObject.image}`}
                                    sx={{ width: 70, height: 25 }}
                                />
                            ) : onlineObject?.name || t('AllOnlineServices')}
                        </Button>
                )}


                {checkFilterButton("BTNPaymentMethod") && (
                    <Button
                        onClick={handleClickFiltersMenu}
                        id="BTNPaymentMethod"
                        variant='outlined'
                        sx={{ padding: 1, margin: 1, width: { xs: "100%", sm: "auto" } }}
                    >
                        <PaymentsIcon />
                        <Divider sx={{ margin: 1 }} />
                        {paymentMethod === null ? t("AllPaymentMethod") : getPaymentMethodName(paymentMethod)}
                    </Button>
                )}







                <Menu
                    id="ShopMenuList"
                    aria-labelledby="ShopMenuList"
                    anchorEl={openMenuAnchor}
                    open={isOpenMenu}
                    onClose={handleCloseFilterMenu}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Box sx={{ minWidth: { xs: "330px", sm: "auto" } }}>
                        {openMenuAnchor?.id === "BTNShopMenu" &&
                            Account.response.shopsEntities.map((data, index) => (
                                <MenuItem
                                    sx={{ textAlign: "center" }}
                                    id="ShopName"
                                    key={index}
                                    onClick={(e) => handleChangeFilterMenu(e, data.id)}
                                >
                                    {data.name}
                                </MenuItem>
                            ))
                        }

                        {openMenuAnchor?.id === "BTNShiftName" && (
                            <>
                                {ListShftNumberAndPriceType.map((data, index) => data !== 0 && (
                                    <MenuItem
                                        id="ShiftName"
                                        key={index}
                                        onClick={(e) => handleChangeFilterMenu(e, index)}
                                    >
                                        {t("Shift") + " " + getShiftName(data)}
                                    </MenuItem>
                                ))}
                                <MenuItem id="ShiftName" onClick={(e) => handleChangeFilterMenu(e, -1)}>
                                    {t("AllWorkShifts")}
                                </MenuItem>
                            </>
                        )}

                        {openMenuAnchor?.id === "BTNPriceType" && (
                            <>
                                {ListShftNumberAndPriceType.map((data, index) => data !== 0 && (
                                    <MenuItem
                                        id="PriceType"
                                        key={index}
                                        onClick={(e) => handleChangeFilterMenu(e, index)}
                                    >
                                        {getServiceName(data)}
                                    </MenuItem>
                                ))}
                                <MenuItem id="PriceType" onClick={(e) => handleChangeFilterMenu(e, -1)}>
                                    {t('AllServices')}
                                </MenuItem>
                            </>
                        )}

                        {openMenuAnchor?.id === "BTNVoidServices" && (
                            <>
                                {ListShftNumberAndPriceType.map((data, index) => data !== 0 && (
                                    <MenuItem
                                        id="VoidServices"
                                        key={index}
                                        onClick={(e) => handleChangeFilterMenu(e, index)}
                                    >
                                        {getVoidServiceName(data)}
                                    </MenuItem>
                                ))}
                                <MenuItem id="VoidServices" onClick={(e) => handleChangeFilterMenu(e, -1)}>
                                    {t('None')}
                                </MenuItem>
                            </>
                        )}

                        {openMenuAnchor?.id === "BTNPaymentMethod" && (
                            <>
                                {ListShftNumberAndPriceType.map((data, index) => data !== 0 && data < 6 && (
                                    <MenuItem
                                        id="PaymentMethod"
                                        key={index}
                                        onClick={(e) => handleChangeFilterMenu(e, index)}
                                    >
                                        {getPaymentMethodName(data)}
                                    </MenuItem>
                                ))}
                                <MenuItem id="PaymentMethod" onClick={(e) => handleChangeFilterMenu(e, -1)}>
                                    {t('AllPaymentMethod')}
                                </MenuItem>
                            </>
                        )}

                        {openMenuAnchor?.id === "BTNOnlineServices" && (


                            <>
                                {onlineSettingsReduser.response.map((data, index) => data !== 0 && (
                                    <MenuItem
                                        id="OnlineServices"
                                        key={index}
                                        onClick={(e) => handleChangeFilterMenuOnline(e, data.id, "OnlineServices")}
                                    >



                                        {data?.image ? (

                                            <Avatar
                                                variant='square'
                                                alt={data?.name}
                                                src={`data:image/jpeg;base64,${data?.image}`}
                                                sx={{ width: 70, height: 25, m: 1 }}
                                            />

                                        ) : data?.name}



                                    </MenuItem>
                                ))}
                                <MenuItem id="OnlineServices" onClick={(e) => handleChangeFilterMenuOnline(e, -1)}>
                                    {t('AllOnlineServices')}
                                </MenuItem>
                            </>
                        )}
                    </Box>
                </Menu>
            </Box>

            <Box sx={{ display: "flex", flexWrap: "wrap", minWidth: "100%", justifyContent: "space-around" }}>
                <Button
                    variant='contained'
                    onClick={updateSearchParams}
                    sx={{ padding: 1, margin: 1, minWidth: "100%" }}
                >
                    <CachedIcon />
                    <Divider sx={{ margin: 1 }} />
                    {t("FetchReports")}
                </Button>
            </Box>

            <Divider sx={{ marginTop: "20px" }} />

            <Typography
                variant='h6'
                color="info.main"
                sx={{ textAlign: "center", marginTop: "10px", marginBottom: "5px" }}
            >
                {getNameReport().toUpperCase()}
            </Typography>

            <ToggleButtonGroup
                color="primary"
                value={viewPage}
                ref={refToggleButtonGroup}
                exclusive
                onChange={handleChangeButtonGroup}
                aria-label="Platform"
                sx={{ width: "100%", overflow: "auto", cursor: "pointer", justifyContent: { lg: "center", md: "center" } }}
            >
                {defultReprtsPageView.map((pageName, index) => (
                    <ToggleButton key={index} value={pageName}>
                        {t(pageName)}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            <Box sx={{ marginTop: "20px", marginBottom: "30px" }} />
        </Box>
    );
}

export default FilterReportsComp;
