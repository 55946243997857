import { Button, Card, CardContent,  CircularProgress, CssBaseline,  Link,  TextField, Typography } from '@mui/material';
import { Box, Container, ThemeProvider } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import APIErorr from '../../Dashboard/Components/APIErorr';
import React, { useState } from 'react';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import Logo from '../../assets/image/logo.png'

import { LoginTheme } from '../../Dashboard/Theme';
import { LoginActions } from '../../redux/slice/User/UserSlice';


function DeleteAccount() {
    const [Logn, setlogin] = useState({ password: "", email: "", userValidate: false, passwordValidate: false });







    const loginReuder = useSelector((state) => state.userReduser);

    const dispatch = useDispatch();






    const onClickSigin = () => {

        dispatch(LoginActions({ "email": "a", "password": Logn.password }))

    }









    const handleChange = e => {
        const { name, value } = e.target;






        if ([name][0] === "email") {
            const re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let userValidate;


            if (re.test(String(value).toLowerCase())) {

                userValidate = true;

            } else {

                userValidate = false;

            }


            setlogin(prevState => ({
                ...prevState,
                [name]: value, userValidate
            }));


        }




        if ([name][0] === "password") {
            let passwordValidate;


            if (value.length > 4) {

                passwordValidate = true;

            } else {

                passwordValidate = false;

            }


            setlogin(prevState => ({
                ...prevState,
                [name]: value, passwordValidate
            }));


        }






    };



    function Copyright(props) {
        return (
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="http://pinetechs.com/">

                    pinetechs.com

                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        );
    }

    const theme = LoginTheme;


    const handleSubmit = (event) => {
        event.preventDefault();

    };


    const IconView = (props) => {

        const Icon = props.icon;


        return (<Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", marginTop: "16px", marginBottom: "8px", minWidth: "20%", background: " linear-gradient(12deg, rgba(67,100,175,1) 0%, rgba(79,171,217,1) 100%)", color: "white !important", border: "0 !important", borderRadius: "0.25rem 0 0 0.25rem !important;" }}>
            <Icon />
        </Box>);



    };



    return (


        <ThemeProvider theme={theme}>
            <Container component="main" style={{ maxWidth: 600 }} >




                <CssBaseline />
                <Card elevation={3} sx={{ marginTop: 8 }}>
                    <CardContent>


                        <Box
                            sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <img src={Logo} alt="logo" style={{ maxWidth: "20%" }}></img>

                            <Typography component="h1" variant="h5" sx={{ marginTop: "10px" }}>
                                Delete Account
                            </Typography>


                            <Typography sx={{ textAlign: "start" ,p:2 }}>

                                Delete Your Account

                                This page allows you to permanently delete your account and all associated personal information. Please be aware that this action is irreversible. Once you delete your account, you will lose access to all services and data, and this information cannot be recovered. If you are sure you want to proceed, please enter your password to confirm your identity and click on the "Delete Account" button. If you have any questions or need further assistance, please contact our support team.



                            </Typography>





                            {loginReuder.loading === "failed" ? <APIErorr reduser={loginReuder} /> : ''}





                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5, minWidth: "80%" }}>



                                <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>

                                    <IconView icon={EmailRoundedIcon} />



                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        value={Logn.email}
                                        onChange={handleChange}
                                        color={Logn.userValidate ? 'success' : 'primary'}
                                        error={!Logn.userValidate && Logn.email.length !== 0 ? true : false}
                                        disabled={loginReuder.loading === "pending"}


                                    />

                                </Box>
                                <Typography variant='body2' color="error.main" display={!Logn.userValidate && Logn.email.length !== 0 ? 'inline' : 'none'}>Incorrect Email</Typography>


                                <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                    <IconView icon={VpnKeyRoundedIcon} />

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        value={Logn.password}
                                        onChange={handleChange}
                                        color={Logn.passwordValidate ? 'success' : 'primary'}
                                        error={!Logn.passwordValidate && Logn.password.length !== 0 ? true : false}
                                        disabled={loginReuder.loading === "pending"}

                                    />
                                </Box>

                                <Typography variant='body2' color="error.main" display={!Logn.passwordValidate && Logn.password.length !== 0 ? 'inline' : 'none'}>Password Email</Typography>
                                <br style={{ display: !Logn.passwordValidate && Logn.password.length !== 0 ? 'inline' : 'none' }} />

                               
                                <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                    <CircularProgress sx={{ display: loginReuder.loading === "pending" ? 'inline' : 'none' }} />


                                </Box>




                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={onClickSigin}

                                    disabled={loginReuder.loading === "pending" || !Logn.passwordValidate || !Logn.passwordValidate}


                                >
                                    Delete 
                                </Button>





                                <Container  >


                                    <Typography sx={{ textAlign: "center" }}>
                                        <a href="http://pinetechs.com/">Forgot your password?</a>

                                    </Typography>


                                </Container>
                            </Box>
                        </Box>
                    </CardContent>

                </Card>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>


    )

}

export default DeleteAccount