

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDeviceId } from "../../../Dashboard/hepler";
import config from '../../../utils/confg.json'


const url = config.BackendUrl;


//| 'pending' | 'succeeded' | 'failed',
const initialState = {
  response: {},
  loading: 'idle',
  httpCode: 0,
  errorMsg: "",
  errorData: {},
  CurrentUser: "idle"
};





export const LoginByTokenActions = createAsyncThunk(
  "user/Login",
  async (dispatch, { rejectWithValue }) => {
    try {

      const { data } = await axios.post(url + "/Account/API/authenticate/AppView/", dispatch.token,
        {
          withCredentials: true
        })

      localStorage.setItem("CurentUser", JSON.stringify(data.accountsEntity));


      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);







export const LoginActions = createAsyncThunk(
  "user/Login",
  async (dispatch, { rejectWithValue }) => {
    try {

      const { data } = await axios.post(url + "/Account/API/authenticate/type/3", { "accountId": 0, "email": dispatch.email, "password": dispatch.password, "deviceId": "0" },
        {
          withCredentials: true
        })

      localStorage.setItem("CurentUser", JSON.stringify(data.accountsEntity));


      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);



export const LogoutActions = createAsyncThunk(
  "user/Logout",
  async (dispatch, { rejectWithValue } ) => {
    try {
      const { data } = await axios.get(url + "/Account/API/authenticate/Logout",
        {
          withCredentials: true
        })
      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);










export const testActions = createAsyncThunk(
  "user/Login",
  async (dispatch, { rejectWithValue }) => {
    try {

      const { data } = await axios.post(url + "/Apps/API/Reports/getMain/ShopId/0/DeviceId/"+getDeviceId()+"/UserType/3/QRKey/0", {},


        {
          withCredentials: true
        }


      );
      return data;
    } catch (err) {

      if (err.response) {
        return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });


      }


    }
  }
);







export const UserSlice = createSlice({

  name: "login",
  initialState,

  reducers: {
    Rest: (state, action) => {

      state.response = {}
      state.loading = 'idle'
      state.httpCode = 0
      state.errorMsg = ""
      state.errorData = {}

    }, getCurrentUser: (state) => {


      if (localStorage.getItem("CurentUser") !== null) {

        state.CurrentUser = localStorage.getItem("CurentUser")
      } else {
        state.CurrentUser = "";


      }
    }



  },



  extraReducers: {
    [LoginActions.pending]: (state) => {
      state.loading = 'pending';
    },
    [LoginActions.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
    },
    [LoginActions.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }
      } catch {

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    } , 





    [LogoutActions.pending]: (state) => {
      localStorage.removeItem("CurentUser")

      state.CurrentUser = "idle"
      state.loading = 'pending';
    },
    [LogoutActions.fulfilled]: (state, action) => {

      state.loading = 'succeeded';
      state.httpCode = 200;
      state.response = action.payload;
      window.location.reload(false);

    },
    [LogoutActions.rejected]: (state, action) => {

      try {
        state.loading = 'failed';
        state.httpCode = action.payload.status;
        state.errorMsg = action.payload.error;
        state.response = {};
        if (action.payload.status === 409) {
          state.errorData = action.payload.data
        } else {

          state.response = {}

        }

        window.location.reload(false);

      } catch {
        window.location.reload(false);

        state.httpCode = 503;
        state.errorMsg = "service unavailable";
      }
    }

  }

})


export const { Rest, getCurrentUser } = UserSlice.actions

export default UserSlice.reducer;
