import React from 'react'


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PieChartIcon from '@mui/icons-material/PieChart';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PublicIcon from '@mui/icons-material/Public';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Link, Outlet, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Collapse, useMediaQuery } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { LogoutActions } from '../../redux/slice/User/UserSlice';
import { useDispatch } from 'react-redux';
import GroupIcon from '@mui/icons-material/Group';
const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop.open !== 'open' && prop.open !== 'matches' })(
    ({ theme, open, matches }) => ({
        backgroundColor: "rgb(255, 255, 255 , .6)",
        overflow: 'auto',
        flexGrow: matches ? 1 : 0,
        padding: matches ? theme.spacing(3) : theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: matches && `+${drawerWidth}px`,
        }),
    }),
);

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open', })




    (({ theme, open }) => ({




        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));








export default function NavBar({ MainView }) {
    const [open, setOpen] = React.useState(false);
    const { i18n } = useTranslation();
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();


    const [openReports, setOpenReport] = useState(false)

    const [openSettings, setOpenSettings] = useState(false)

    const [openLangSettings, setOpenLangSettings] = useState(false)

    const theme = useTheme()
    const matches = useMediaQuery((theme) => theme.breakpoints.up('md'));


    let params = "";

    let x = 0;
    for (let entry of searchParams.entries()) {
        if (entry[0].toLowerCase() === "shopid" || entry[0].toLowerCase() === "from" || entry[0].toLowerCase() === "to") {
            if (x !== 0) {
                params = params + "&" + entry[0] + "=" + entry[1];
            } else {
                params = params + entry[0] + "=" + entry[1];
            }

            x++;
        }
    }




    const changeLang = (value) => {

        i18n.changeLanguage(value)

    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (

        <Box>

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar theme={theme} color='customA' position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="div">
                            Pinetechs Portal ver 1.01
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer

                    dir={i18n.dir()}
                    sx={{
                        width: drawerWidth,
                        flexShrink: matches ? 0 : 2,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },

                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader >

                        <IconButton onClick={handleDrawerClose}>
                            {i18n.dir() === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Home"}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("Dashboard")} />
                            </ListItemButton>
                        </ListItem>
                        <ListItemButton name="Reports" onClick={() => setOpenReport(!openReports)} >
                            <ListItemIcon>
                                <PieChartIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("Reports")} />
                            {openReports ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={openReports} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Reports/MainReports?" + params} sx={{ pl: 4 }} >
                                    <ListItemIcon>
                                        <PriceChangeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("CashReports")} />
                                </ListItemButton>

                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Reports/VoidedTransctionsReports?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <DeleteForeverIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("VoidedTransctions")} />
                                </ListItemButton>


                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Reports/ItemsSoldReports?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <CategoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("ItemsSold")} />
                                </ListItemButton>
                                <ListItemButton onClick={()=>setOpen(false)}  component={Link} to={"/Dashboard/Reports/ViewInvoices?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <ReceiptLongIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("ViewInvoices")} />
                                </ListItemButton>
                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Reports/StoreLogsReports?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <StoreMallDirectoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("StoreLogsReports")} />
                                </ListItemButton>

                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Reports/ItemsInventory?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <ManageSearchIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("ItemsInventory")} />
                                </ListItemButton>

                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Reports/NoteAndExtraInventory?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PlagiarismIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("NoteAndExtraInventory")} />
                                </ListItemButton>
                            </List>
                        </Collapse>





                        <ListItemButton name="Settings" onClick={() => setOpenSettings(!openSettings)} >
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("Settings")} />
                            {openSettings ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>


                        <Collapse in={openSettings} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Settings/Items?" + params} sx={{ pl: 4 }} >
                                    <ListItemIcon>
                                        <ProductionQuantityLimitsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("MainItems")} />
                                </ListItemButton>

                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Settings/Category?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <WidgetsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Categories")} />
                                </ListItemButton>


                                <ListItemButton  onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Settings/NoteAndExtra?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <CategoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("NoteAndExtra")} />
                                </ListItemButton>
                                <ListItemButton onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Settings/MandatoryItems?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <AssignmentTurnedInIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("MandatoryItems")} />
                                </ListItemButton>
                                <ListItemButton onClick={()=>setOpen(false)}  component={Link} to={"/Dashboard/Settings/KitchenMonitor?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <ScreenshotMonitorIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("KitchenMonitor")} />
                                </ListItemButton>

                                <ListItemButton onClick={()=>setOpen(false)}  component={Link} to={"/Dashboard/Settings/DescribeReasonMessages?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <ModeEditIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("ReasonMessages")} />
                                </ListItemButton>

                                <ListItemButton  onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Settings/UserPermissions?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <GroupIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("UsersPermissions")} />
                                </ListItemButton>


                                <ListItemButton  onClick={()=>setOpen(false)} component={Link} to={"/Dashboard/Settings/PartnerCard?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <ArticleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("PartnersCards")} />
                                </ListItemButton>

                                <ListItemButton onClick={()=>setOpen(false)}  component={Link} to={"/Dashboard/Settings/Store?" + params} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <StorefrontIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t("Store")} />
                                </ListItemButton>
                            </List>
                        </Collapse>




                    </List>
                    <Divider />
                    <List>





                        <ListItemButton name="Language" onClick={() => setOpenLangSettings(!openLangSettings)} >
                            <ListItemIcon>
                                <GTranslateIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("Language")} />
                            {openLangSettings ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>


                        <Collapse in={openLangSettings} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton onClick={() => { changeLang("en"); setOpen(false)}} sx={{ pl: 4 }} >
                                    <ListItemIcon>
                                        <PublicIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"English"} />
                                </ListItemButton>

                                <ListItemButton onClick={() => {changeLang("ar");setOpen(false)}} sx={{ pl: 4 }}>
                                    <ListItemIcon>
                                        <PublicIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"العربية"} />
                                </ListItemButton>


                            </List>
                        </Collapse>
                        <ListItem disablePadding>
                            <ListItemButton onClick={()=> dispatch(LogoutActions())}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary={t("Logout")} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Drawer>

            </Box>
            <Main open={open} matches={matches}>

                <DrawerHeader />



                <Outlet />


            </Main>
        </Box>

    );
}

