import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDeviceId } from "../../../../Dashboard/hepler";
import config from '../../../../utils/confg.json'
const url = config.BackendUrl;
//| 'pending' | 'succeeded' | 'failed',
const initialState = {
    response: {},
    loading: 'idle',
    httpCode: 0,
    errorMsg: "",
    errorData: {},
  };
  

  export const saveKitchenMonitorSetting = createAsyncThunk(
    "saveKitchenMonitorSetting/saveKitchenMonitorSetting",

    async (dispatch, { rejectWithValue }) => {
      try {

        const shopId = dispatch.shopId
        const monitorId = dispatch.monitorId ;
        const dataSaved = dispatch.dataSaved ; 

    
        const { data } = await axios.post(url + "/Apps/API/CMS/Settings/KitchenMonitor/save?ShopId="+shopId+"&DeviceId="+getDeviceId()+"&QRKey=0&MonitorId="+monitorId ,{...dataSaved},
          {
            withCredentials: true
          })
        return data;
      } catch (err) {

  
        if (err.response) {
          return rejectWithValue({ error: err.message, status: err.response.status, data: err.response.data });
  
  
        }
  
  
      }
    }
  );




 



  export const saveKitchenMonitorSettingSlices = createSlice({

    name: "saveKitchenMonitorSetting",
    initialState,
  
    reducers: {
      reset: () => initialState
    },
    extraReducers: {
      [saveKitchenMonitorSetting.pending]: (state) => {
        state.loading = 'pending';
      },
      [saveKitchenMonitorSetting.fulfilled]: (state, action) => {
  
        state.loading = 'succeeded';
        state.httpCode = 200;
        state.response = action.payload;
      },
      [saveKitchenMonitorSetting.rejected]: (state, action) => {
  
        try {
          state.loading = 'failed';
          state.httpCode = action.payload.status;
          state.errorMsg = action.payload.error;
          state.response = {};
          if (action.payload.status === 409) {
            state.errorData = action.payload.data
          } else {
  
            state.response = {}
  
          }
        } catch {
  
          state.httpCode = 503;
          state.errorMsg = "service unavailable";
        }
      }







  
    }
  
  })
  
  export const { reset  } = saveKitchenMonitorSettingSlices.actions
  
  export default saveKitchenMonitorSettingSlices.reducer;