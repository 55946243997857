import React from 'react'
import { Navigate, Route, Routes, } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard'
import Home from '../Dashboard/Pages/Home'
import Report from '../Dashboard/Pages/Reports/Report'
import Login from '../Dashboard/Login/Login'
import Setting from '../Dashboard/Pages/Settings/Setting'
import CardErorr from '../Dashboard/Components/CardErorr'
import { t } from 'i18next'
import AppView from '../Dashboard/AppView'
import DeleteAccount from '../DeleteAccount/Pages/DeleteAccount'

export default function MasterRoute() {
  return (
    <Routes>

      <Route path="/Dashboard" element={<Dashboard />}  >
        <Route path="Home" element={<Home />} />
        <Route path='Reports/:viewPage' element={<Report />} />
        <Route path='Settings/:viewPage'element={<Setting />} />
        <Route path='Settings/:viewPage/:viewSubPage'element={<Setting />} />
        <Route path="" element={<Navigate to="/Dashboard/Home" replace />} />
        <Route path="*" element={<CardErorr Msg={t("PageNotfound")}></CardErorr>} />
      </Route>




      <Route path='/APPView/:viewPage' element={<AppView />} />
      <Route path='/DeleteAccount' element={<DeleteAccount />} />

      
      <Route path='/' element={<Login />} />





    </Routes>
  )
}
