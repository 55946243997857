import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'

import { Avatar, Card, CardContent, Checkbox, Divider, FormControlLabel, FormGroup, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import List from '@mui/material/List';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Progress from '../../../Components/Progress';
import APIErorr from '../../../Components/APIErorr';
import i18n from '../../../../i18n';
import SquareIcon from '@mui/icons-material/Square';
import { getHexColor } from '../../../hepler';
import { getNoteAndExtraSetting, reset } from '../../../../redux/slice/Settings/NoteAndExtra/getAllNoteAndExtraSettingSlices';



const getAvatar = (value, isCategory) => {


  const dir = i18n.dir()

  if (value.color === 0) {
    return <Avatar alt={value.name} src={`data:image/jpeg;base64,${value.image}`} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50 }} />

  }


  if (value.color !== null) {



    return <Avatar alt={value.name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: getHexColor(value.color), float: dir === "rtl" ? "right" : "left", marginLeft: 1, marginRight: 1 }} > </Avatar>


  }

  return <Avatar alt={value.name} sx={{ width: isCategory ? 25 : 50, height: isCategory ? 25 : 50, bgcolor: "rgb(231, 67, 182)", float: dir === "rtl" ? "right" : "left", marginLeft: 1, marginRight: 1 }}  > <SquareIcon /> </Avatar>


}









function NoteAndExtraListWithCheckComp(props) {

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [ItemsList, setItemsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0)
  const [hasMore, setHasMore] = useState(true);

  const shopId = searchParams.get("ShopId")
  const mainReduser = useSelector((state) => state.noteAndExtraSettingReduser);
  const response = mainReduser.response;
  const sortBy = "id";
  const sortOrder = "desc"
  const pageSize = 20;



  const attributeName = [["adapterTybe", "defaultItemsEntity"], ["monitorId", "monitorItemsSettingsEntityList"], ["Quantity", "itemList"]]




  const disabled = props.disabled;
  const [dataObject, setDataObject] = props.control

  const listType = Number(props.listType);

  const lischeck = props["control"][0][attributeName[listType][1]]
  const adapterTybe = props[attributeName[listType][0]]


  const findIdOfItem = (id) => {
    const value = lischeck.find((element) => (element.itemId === id));
    if (value !== undefined) {
      return true;
    }
    return false;
  }




  const handelChangeList = (e, cheked, repeat) => {
    let id;


    try {
      id = Number(e.target.value);
    } catch (error) {
      id = e;

    }







    if (cheked) {


      if (listType === 2) {


        let data = {
          ...ItemsList.filter(function (obj) {
            return obj.id === id;
          })[0], "itemId": id, "id": 0, "qtyNew": 1
        }

        let tempList = dataObject[attributeName[listType][1]]
        tempList = [...tempList, data];
        setDataObject({ ...dataObject, [attributeName[listType][1]]: tempList })

      } else {

        const data = { "id": 0, "itemId": id, [attributeName[listType][0]]: adapterTybe }
        let tempList = dataObject[attributeName[listType][1]]
        tempList = [...tempList, data];
        setDataObject({ ...dataObject, [attributeName[listType][1]]: tempList })

      }



    } else {


      const tempList = dataObject[attributeName[listType][1]].filter(function (obj) {
        return obj.itemId !== id;
      });


      setDataObject({ ...dataObject, [attributeName[listType][1]]: tempList })
    }

    if (repeat) {

      handelChangeList(e, !cheked, false)
    }

  }














  useEffect(() => {

    const pageNumber = 0
    dispatch(reset())
    setHasMore(true);
    setItemsList([]);
    setPageNumber(0);
    dispatch(getNoteAndExtraSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))
    setItemsList([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, adapterTybe])




  useEffect(() => {

    dispatch(getNoteAndExtraSetting({ shopId, pageNumber, pageSize, sortBy, sortOrder }))


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, dispatch])





  const moveNext = () => {

    if (ItemsList.length < response.totalElements) {
      setPageNumber(pageNumber + 1)

    } else {
      setHasMore(false)
    }
  }



  useEffect(() => {


    if (mainReduser.loading === 'succeeded') {
      let addedList = ItemsList;
      addedList = response.content.filter((obj2) => {
        let checkAddNew = true;
        ItemsList.forEach((obj) => {
          if (obj2.id === obj.id) {
            checkAddNew = false;
          }
        })
        return checkAddNew && obj2
      })
      setItemsList([...ItemsList, ...addedList])



    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainReduser.loading])








  if (mainReduser.loading === "failed") {
    return (<APIErorr reduser={mainReduser} />)
  }

  if (((mainReduser.loading === "pending" || mainReduser.loading === "idle")) || shopId === null) {


    return (<Progress />)
  }


  if (mainReduser.loading === "failed") {
    return (<APIErorr reduser={mainReduser} />)
  }











  return (
    <Card >



      <Divider sx={{ margin: 2 }} />




      <CardContent id="scrollableDiv" sx={{ height: 500, overflow: !disabled ? 'auto' : 'hidden' }}>






        <List sx={{ mr: -2, ml: -2 }}  >

          {ItemsList !== undefined &&
            <InfiniteScroll
              dataLength={ItemsList.length}
              next={moveNext}
              hasMore={hasMore}
              style={{ display: 'flex', flexDirection: "column" }} //To put endMessage and loader to the top.
              loader={mainReduser.loading === "pending" && <Progress />}
              scrollableTarget="scrollableDiv"



            >

              {ItemsList.map((data, index) => (
                <Box key={index}
                  sx={{ marginBottom: 2, marginTop: 1 }}>
                  <ListItem
                    className="list-item"
                    onClick={() => handelChangeList(Number(data.id), findIdOfItem(data.id), true)}
                    secondaryAction={

                      <Box sx={{ display: 'flex', flexDirection: "row", flexFlow: "wrap" }}>
                        <FormGroup >
                          <FormControlLabel control={<Checkbox onChange={handelChangeList} disabled={disabled} value={Number(data.id)} checked={findIdOfItem(data.id)} />} labelPlacement="end"
                          />

                        </FormGroup>



                      </Box>


                    }


                  >
                    <ListItemAvatar>
                      {getAvatar(data, false)}
                    </ListItemAvatar>
                    <ListItemText
                      primary={data.name}

                    />
                  </ListItem>


                  <Divider />
                </Box>

              ))}

            </InfiniteScroll>}


        </List>


      </CardContent>



    </Card>

  )
}

export default NoteAndExtraListWithCheckComp