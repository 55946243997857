import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider, Typography } from '@mui/material';
import Cropper from 'react-easy-crop';
import { useState } from 'react';
import { useCallback } from 'react';
import { getCroppedImg } from './canvasUtils';
import { t } from 'i18next';
import { useRef } from 'react';

async function convertToFile(url) {
    let response = await fetch(url);
    let blob = await response.blob();

    return new File([blob], 'put_the_name.jpg', {
        type: 'image/jpeg'
    });
}




function ImageDialog(props) {


    const [itemObject, setItemObject] = props.data;

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [open, setOpen] = props.control


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {

        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                itemObject.imageTemp,
                croppedAreaPixels,
                rotation
            )

            const file = await convertToFile( croppedImage ); // usage of function above



            const reader = new FileReader();
            if (file) {
     
                reader.readAsDataURL(file)

                reader.onloadend = function () {
                    setItemObject({ ...itemObject, color: 0, image: reader.result.split(";base64,")[1] });

                }

            }



        } catch (e) {
            console.error(e)
        }

        setOpen(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemObject.imageTemp, croppedAreaPixels, rotation])

    const handleClose = useCallback(() => {

        setOpen(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])











    const descriptionElementRef = useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"md"}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{t("EditImage")}</DialogTitle>
                <DialogContent dividers={true}>
                    <Box >
                        <Cropper
                            image={itemObject.imageTemp}
                            crop={crop}
                            zoom={zoom}
                            aspect={5 / 4}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            objectFit='vertical-cover'
                            style={{ containerStyle: { position: 'relative' } }}
                            rotation={rotation}



                        />

                    </Box>

                    

                </DialogContent>

                
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" , pr:4, pl:4}}>
                            <div >
                                <Typography
                                    variant="overline"
                                >
                                    {t("Zoom")}
                                </Typography>
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby={t("Zoom")}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </div>
                            <div >
                                <Typography
                                    variant="overline"
                                >
                                    {t("Rotation")}
                                </Typography>
                                <Slider
                                    value={rotation}
                                    min={0}
                                    max={360}
                                    step={1}
                                    aria-labelledby={t("Rotation")}
                                    onChange={(e, rotation) => setRotation(rotation)}
                                />
                            </div>

                        </Box>

                <DialogActions>
                    <Button onClick={showCroppedImage}>{t("Save")}</Button>
                    <Button onClick={handleClose}>{t("Cancel")}</Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImageDialog