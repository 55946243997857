import { Button, Card, CardActionArea, CardContent, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import AnimatedNumber from "animated-number-react";
import Progress from '../Components/Progress';
import APIErorr from '../Components/APIErorr';
import { getMainReportActions } from '../../redux/slice/Reports/mainReportsSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getShiftName } from './../hepler'
import { Link  } from "react-router-dom";

export default function Home(proprs) {


  const account = useSelector((state) => state.accountReduser);


  const mainReports = useSelector((state) => state.mainReportsReduser)



  const dispatch = useDispatch();


  useEffect(() => {


    dispatch(getMainReportActions())




  }, [dispatch])




  const { t } = useTranslation();



  const ViewAccountInfo = () => {

    const accountsEntity = account.response.accountsEntity;


    return (
      <Box>

        <Typography variant="subtitle2" color="text.secondary">{t("Name")} : {accountsEntity.firstName + " " + accountsEntity.familyName}</Typography>
        <Typography variant="subtitle2" color="text.secondary">{t("Email")} : {accountsEntity.email}</Typography>
        <Typography variant="subtitle2" color="text.secondary">{t("Company")} : {accountsEntity.companyName}</Typography>
        <Typography variant="subtitle2" color="text.secondary">{t("Phone")} :  {"+" + accountsEntity.countryCode + " " + accountsEntity.phoneNumber}</Typography>

      </Box>
    )



  }


  const ViewStores = () => {

    const Reports = mainReports.response;


    const ViewShopInformation = (props) => {

    

      const shopId = props.shopId;




      if (account.loading === "pending") {

        return (<Progress />)
      }
      if (account.loading === "failed") {
        return (<APIErorr reduser={account} />)
      }




      if (account.loading === "succeeded") {

        const shopsEntities = account.response.shopsEntities;



        return shopsEntities.map((shop, index) => {





          if (shopId === shop.id) {
            return (


              <Grid key={index} container spacing={1}>




                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {t("TimeZone")} : {shop.timeZoneId}

                  </Typography>
                  <Divider sx={{ marginRight: 2, marginLeft: 2 }} />

                </Grid>

                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {t("Currency")} : {shop.currency}


                  </Typography>
                  <Divider sx={{ marginRight: 2, marginLeft: 2 }} />

                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {t("CashDayName")} : {moment(shop.businessDayNameById.businessDayName, "YYYY-MMM-DD").format('YYYY-MM-DD')}

                  </Typography>
                  <Divider sx={{ marginRight: 2, marginLeft: 2 }} />

                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {t("CurrentWorkShift")} : {getShiftName(shop.businessDayNameById.shiftName)}

                  </Typography>
                  <Divider sx={{ marginRight: 2, marginLeft: 2 }} />

                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {t("LicenceExpired")} : {shop.licenceExpired.split("T")[0]} 06:13:01
                  </Typography>
                </Grid>


              </Grid >










            )


          }
          return "";


        })






      }







    }



    return (


      <Box sx={{ display: "flex", flexFlow: "wrap", minWidth: "100%" }}>





        {Reports.map((shop, index) => (



          <Card key={index} sx={{ maxWidth: { md: "100%", lg: "40%" }, padding: 4, margin: 1, borderLeftStyle: "groove", borderLeftColor: "primary.light", borderLeftWidth: 5, borderRadius: "10px 10px 10px 10px", border: "10px 1px" }}>

            <CardContent>
              <Box sx={{ display: "flex", flexFlow: "wrap", justifyContent: "space-around" , minWidth:"100%" }}>
                <Typography gutterBottom variant="h4" color="primary.light" component="div" margin={2} >
                  {shop.shopName}
                </Typography>
                <Typography margin={2} gutterBottom variant="subtitle2" component="div" color={shop.cashDayOpen ? "success.light" : "error.main"} >
                  {shop.cashDayOpen ? t("CashIsOpen") : t("CashIsColse")}
                </Typography>
              </Box>
              <Divider sx={{ margin: 2 }} />

              <ViewShopInformation shopId={shop.shopId} />

              <Divider sx={{ margin: 2 }} />
              <Typography gutterBottom variant="h5" component="div" textAlign="Center" color="primary.light">
                { shop.cashDayName !== null && shop.cashDayName.split("T")[0]}
              </Typography>
              <Box sx={{ display: "flex", flexFlow: "wrap", justifyContent: "center", alignContent: "center" }}>
                <Box sx={{ display: "flex", flexFlow: "wrap", flexDirection: "column", justifyContent: "center", alignContent: "center", margin: 1, paddingLeft: 5, paddingRight: 5 }}>
                  <Typography variant="caption" color="primary.light" sx={{ textAlign: "center" }}>{t("Count").toLocaleUpperCase()}</Typography>

                  <Typography variant="h4" color="secondary.dark" sx={{ textAlign: "center" }} ><AnimatedNumber formatValue={n => n.toFixed(0)}
                    value={shop.totalRefund} duration="2000" /></Typography>
                  <Typography variant="subtitle2" color="primary.light" sx={{ textAlign: "center" }}>{t("Refund").toLocaleUpperCase()}</Typography>

                </Box>
                <Box sx={{ display: "flex", flexFlow: "wrap", flexDirection: "column", justifyContent: "center", alignContent: "center", margin: 1, paddingLeft: 5, paddingRight: 5 }}>
                  <Typography variant="caption" color="primary.light" sx={{ textAlign: "center" }}>{shop.currency.toUpperCase()}</Typography>

                  <Typography variant="h4" color="success.light" sx={{ textAlign: "center" }} ><AnimatedNumber formatValue={n => n.toFixed(3)}
                    value={shop.totalCash} duration="2000" /></Typography>
                  <Typography variant="subtitle2" color="primary.light" sx={{ textAlign: "center" }}>{t("TotalCash").toLocaleUpperCase()}</Typography>

                </Box>

                <Box sx={{ display: "flex", flexFlow: "wrap", flexDirection: "column", justifyContent: "center", alignContent: "center", margin: 1, paddingLeft: 1, paddingRight: 1 }}>
                  <Typography variant="caption" color="primary.light" sx={{ textAlign: "center" }}>{shop.currency.toUpperCase()}</Typography>
                  <Typography variant="h4" color="error.dark" sx={{ textAlign: "center" }} ><AnimatedNumber formatValue={n => n.toFixed(3)}
                    value={shop.totalDiscount} duration="2000" /></Typography>
                  <Typography variant="subtitle2" color="primary.light" sx={{ textAlign: "center" }}>{t("TotalDiscounts").toLocaleUpperCase()}</Typography>

                </Box>



              </Box>
              <Divider sx={{ margin: 2 }} />
              {shop.cashDayName != null && <Button variant='contained'  component={Link}  to={"/Dashboard/Reports/MainReports?ShopId="+shop.shopId+"&From="+shop.cashDayName.split("T")[0]+"&To="+shop.cashDayName.split("T")[0]} sx={{minWidth:"100%"}} >{t("Details").toLocaleUpperCase()}</Button> }

            </CardContent>

          </Card>




        ))}








      </Box>



    )




  }

  return (


    <Box>

      <Typography variant='h4'>{t("HomePage")}</Typography>

      <Divider sx={{ margin: 3 }} />



      <Card sx={{ maxWidth: "100%" }}>

        <CardActionArea>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {t("AccountInformations")}
            </Typography>
            <Divider sx={{ margin: 2 }} />

            {account.loading === "pending" ? <Progress /> : ""}
            {account.loading === "failed" ? <APIErorr reduser={account} /> : ''}
            {account.loading === "succeeded" ? <ViewAccountInfo /> : ''}



          </CardContent>

        </CardActionArea>
      </Card>

      <Typography variant='h5' sx={{ marginTop: 3 }}>{t("Stores")}</Typography>
      <Divider sx={{ margin: 3 }} />


      {mainReports.loading === "pending" ? <Progress /> : ""}
      {mainReports.loading === "failed" ? <APIErorr reduser={account} /> : ''}
      {mainReports.loading === "succeeded" ? <ViewStores /> : ''}







    </Box>


  )
}
